'use strict';
import Cookies from 'js-cookie';
// MENU SELECTORS
const hamburger = document.querySelector('.fa-bars');
const mobileMenu = document.querySelector('.m-menu');

//TOTOP SELECTORS
const nav = document.querySelector('nav');
const btnTopAppear = document.querySelector('.stables');
const toTopBtn = document.querySelector('.btn-top');

// SECTIONS SELECTORS
const stables = document.querySelector('.partners');
const findUs = document.querySelector('.hitta-hit');
const anlaggningPhotos = document.querySelector('.anlaggning-photos');
const anlaggningPhotosFlex = document.querySelector('.anlaggning-photos-flex');
const operations = document.querySelector('.blacksmith');
const motto = document.querySelector('.verksamheter');
const contactAmicus = document.querySelector('.contact-form-amicus');
const footer = document.querySelector('footer');

// BUTTON SELECTORS
const btnReadMore = document.querySelector('.btn-read-more');
const btnFindUs = document.querySelector('.btn-hitta-hit');
const btnPhotos = document.querySelector('.btn-bilder');
const btnMotto = document.querySelector('.btn-motto');
const btnIntresse = document.querySelector('.btn-intresse');
const btnOperations = document.querySelector('.btn-verksamheter');
const btnLogo = document.querySelector('.logo');
const btnFacebook = document.querySelector('.facebook');
const btnInstagram = document.querySelector('.instagram');
const btnMail = document.querySelector('.mail');
const btnArrowDown = document.querySelector('.fa-chevron-down');

// Click logo to return home

btnLogo.addEventListener('click', function () {
  location.href = './index.html';
});

// MOBILE MENU

let navToggle = false;

// Closes the mobile menu when clicking outside of it(when open)

window.addEventListener('click', function (e) {
  if (e.target !== mobileMenu && e.target !== hamburger) {
    mobileMenu.style.transform = 'translateX(150%)';
    navToggle = false;
  }
});

hamburger.addEventListener('click', function (e) {
  if (navToggle === false) {
    // mobileMenu.classList.remove('hidden');
    mobileMenu.style.transform = 'translateX(0)';
    navToggle = true;
    return;
  }
  if (navToggle === true) {
    mobileMenu.style.transform = 'translateX(150%)';
    navToggle = false;
  }
});
// TOP BTN

const observerCallback = function (entries) {
  const [entry] = entries;
  if (!toTopBtn) return;
  if (!entry.isIntersecting && window.innerWidth < 992) {
    //Render the top-button
    toTopBtn.style.display = 'block';
  } else {
    toTopBtn.style.display = 'none';
  }
};

const observerOptions = {
  root: null,
  threshold: 0,
};

const observer = new IntersectionObserver(observerCallback, observerOptions);

observer.observe(nav);

// ToTop event listener

toTopBtn?.addEventListener('click', function () {
  const topCoords = nav.getBoundingClientRect();
  window.scrollTo(topCoords.left, topCoords.top);
});

// BUTTON EVENTS

// Arrow down

const arrowDown = document.querySelector('.fa-angle-double-down');
const entryPage = document.querySelector('.entry-page');
const anlaggningPhotosDesktop = document.querySelector(
  '.anlaggning-photos-flex'
);
const nextElementScroll = entryPage?.nextElementSibling;

arrowDown?.addEventListener('click', function () {
  anlaggningPhotosDesktop?.scrollIntoView({ behavior: 'smooth' });
  nextElementScroll.scrollIntoView({ behavior: 'smooth' });
});

// HEM
btnReadMore?.addEventListener('click', function () {
  stables.scrollIntoView({ behavior: 'smooth' });
});

// Anläggning
btnFindUs?.addEventListener('click', function () {
  findUs.scrollIntoView({ behavior: 'smooth' });
});

btnPhotos?.addEventListener('click', function () {
  anlaggningPhotos?.scrollIntoView({ behavior: 'smooth' });
  anlaggningPhotosFlex?.scrollIntoView({ behavior: 'smooth' });
});

// VERKSAMHET
btnMotto?.addEventListener('click', function () {
  motto.scrollIntoView({ behavior: 'smooth' });
});

btnOperations?.addEventListener('click', function () {
  operations.scrollIntoView({ behavior: 'smooth' });
});

// STALL AMICUS

btnIntresse?.addEventListener('click', function () {
  contactAmicus.scrollIntoView({ behavior: 'smooth' });
});

// Social buttons in footer

btnFacebook.addEventListener('click', function () {
  window.location.href = 'https://www.facebook.com/Amicusequestrian/';
});

btnInstagram.addEventListener('click', function () {
  window.location.href = 'https://www.instagram.com/amicusequestrian/';
});

btnMail.addEventListener('click', function () {
  window.location.href = 'mailto:info@amicusec.se';
});

// Cookies msg and actual cookie

const cookieHTML =
  '<div class="cookies"><span>Vi använder cookies för funktioner på sidan. Vi utgår från att det är okej om du stannar kvar på sidan.</span><button class="btn-cookies">Okej!</button></div>';

window.addEventListener('load', function () {
  if (!Cookies.get('consent')) {
    footer.insertAdjacentHTML('afterend', cookieHTML);
    const cookieBtn = document.querySelector('.btn-cookies');
    const cookieDiv = document.querySelector('.cookies');
    cookieBtn.addEventListener('click', function () {
      cookieDiv.classList.add('hidden');
      Cookies.set('consent', 'true', { expires: 1 });
    });
  }
});

// RECAPTCHA
if (document.getElementById('token')) {
  grecaptcha.ready(function () {
    grecaptcha
      .execute('6Lc6UokcAAAAAOaOPjujFVk5v9IScB8OU9Mu14FH', {
        action: 'homepage',
      })
      .then(function (token) {
        // console.log(token);
        document.getElementById('token').value = token;
      });
    // refresh token every minute to prevent expiration
    setInterval(function () {
      grecaptcha
        .execute('6Lc6UokcAAAAAOaOPjujFVk5v9IScB8OU9Mu14FH', {
          action: 'homepage',
        })
        .then(function (token) {
          console.log('refreshed token:', token);
          document.getElementById('token').value = token;
        });
    }, 60000);
  });
}
